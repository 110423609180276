/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, image: metaImage, url: metaurl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const image = metaImage 
	? metaImage
	: null;
  const url = metaurl
  ? metaurl
  :null;
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  var { pathname } = useLocation()
  
  if (pathname == "/home" || pathname == "/home/" )
    pathname = ""
  if (pathname == "/SearchResult/SearchResult/" || pathname == "/SearchResult/SearchResult")
    pathname = "/property/for-sale/in-manchester/"
  if (pathname == "/PropertyDetails/PropertyDetails/" || pathname == "/PropertyDetails/PropertyDetails")
    pathname = "/property/for-sale/in-manchester/"
  if (pathname == "/property-to-rent/" || pathname == "/property-to-rent")
    pathname = "/property/to-rent/in-manchester/"
  var curentUrl = '';
  curentUrl = process.env.GATSBY_SITE_URL+pathname;
  if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
          {
              href: curentUrl,
              rel: "canonical"
          }
      ]}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:url`,
          content: curentUrl,
        },
        {
          property: `og:image`,
          content: `${process.env.GATSBY_SITE_URL}/images/mail-logo.png`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `ghjv8ij4zmvi4tnvzx59bi5171rqn0`,
        },
        {
          name: `google-site-verification`,
          content: `google5bb810564c0de992`,
        },
      ].concat(
        metaImage
          ? [
            {
            property: "og:image",
            content: image,
            },
            {
            property: "og:image:width",
            content: 717,
            },
            {
            property: "og:image:height",
            content: 428,
            },
            {
            name: "twitter:card",
            content: "summary_large_image",
            },
            {
             property: "og:url",
             content:curentUrl,
            },
          ]
          : [
            {
            name: "twitter:card",
            content: "summary",
            },
          ]
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
		src: PropTypes.string.isRequired
	})
}

export default SEO
